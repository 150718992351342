$( document ).ready(function() {
    $(".js-select").styler();


    $(".news__name").dotdotdot({
		height: 45,
		fallbackToLetter: true,
		watch: true,
    });
	
	
    $(".project__link a").dotdotdot({
		height: 25,
		fallbackToLetter: true,
		watch: true,
	});

    $(".project__name").dotdotdot({
		height: 45,
		fallbackToLetter: true,
		watch: true,
	});

	$(".project--science .project__text").dotdotdot({
		height: 45,
		fallbackToLetter: true,
		watch: true,
	});
});